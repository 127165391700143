<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-margin">
      <div uk-grid class="uk-grid-small">
        <div class="uk-inline">
          <button
            class="uk-button uk-button-small-right uk-button-default"
            type="button"
            @click="backSchedule"
          >
            <span class="uk-margin-small-right" uk-icon="icon: arrow-left"></span>
            Back
          </button>
        </div>
        <div class="uk-inline">
          <button style="width:100px; cursor:pointer; height:40px" class="uk-button-primary" @click="showCreateModal">Create</button>
        </div>
        <div class="uk-inline">
          <button v-if="this.meta.from_date == ''" style="width:100px; cursor:pointer; height:40px" class="uk-button-default" @click="showFilterModal">Filter Date</button>
          <button v-else style="width:100px; cursor:pointer; height:40px" class="uk-button-danger" @click="clearFilterDate">Reset Date</button>
        </div>
        <div class="uk-inline" style="margin: auto; margin-left: 2%">
          <h4>{{company_office.name}} - {{company_office.code}}</h4>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin">
      <div class="uk-overflow-auto">
        <table class="uk-table uk-table-small uk-table-divider uk-table-striped">
          <thead>
            <tr>
              <th class="uk-text-center">Schedule ID</th>
              <th class="uk-text-center uk-table-expand">Valid Date</th>
              <th class="uk-text-center uk-table-expand">Shift Used</th>
              <th class="uk-text-center uk-table-expand">Time</th>
              <th class="uk-text-center">Day Valid</th>
              <th class="uk-text-center">Workers</th>
              <th class="uk-text-center">Status</th>
              <th class="uk-text-center">Actions</th>
            </tr>
          </thead>
          <tbody v-if="schedules.docs.length>0" class="uk-table-middle uk-text-center ">
            <tr v-for="(schedule, i) in schedules.docs" :key="i">
              <td>{{schedule._id}}</td>
              <td v-if="schedule.valid_to">{{formatDate(schedule.valid_from)}} - <br>{{formatDate(schedule.valid_to)}}</td>
              <td v-else>{{formatDate(schedule.valid_from)}} - <br>Now</td>
              <td>{{schedule.company_shift.name}}</td>
              <td>{{formatTime(schedule.company_shift.start_shift.hour)}}.{{formatTime(schedule.company_shift.start_shift.minute)}} -
                  {{formatTime(schedule.company_shift.end_shift.hour)}}.{{formatTime(schedule.company_shift.end_shift.minute)}}</td>
              <td>{{days(schedule)}}</td>
              <td>{{schedule.users.length}}</td>
              <td>
                <div class="label uk-margin-auto" :style="`background-color: ${schedule.send_notif == 2 ? '#00cc00' : '#e63c3c'}`">
                  {{schedule.send_notif == 2 ? 'Sent' : 'Not Sent'}}
                </div>
              </td>
              <td>
                  <button
                  style="width:100px; cursor:pointer; height:30px; margin-bottom:5px; background:#333747"
                  class="uk-button-primary"
                  type="button"
                  v-show="schedule.send_notif != 2"
                  @click="showNotifyModal(schedule)"
                >
                  Notify
                </button>
                <button
                  style="width:100px; cursor:pointer; height:30px; margin-bottom:5px"
                  class="uk-button-default"
                  type="button"
                  @click="showEditModal(schedule)"
                >
                  Edit
                </button>
                <button
                  style="width:100px; cursor:pointer; height:30px"
                  class="uk-button-danger"
                  type="button"
                  @click="showDeleteModal(schedule)"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
          <empty-table v-else :colspan="6" />
        </table>
      </div>
      <pagination
        :total-data="schedules.totalDocs"
        :change-limit="changeLimit"
        :change-page="changePage"
      />
    </div>

    <div id="filterDateModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideFilterModal"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Input Date Range</h2>
        </div>
        <div class="uk-modal-body">

          <div class="uk-margin">
            <label class="uk-form-label">From</label>
            <datepicker
              input-class="uk-input"
              name="start_date"
              v-model="meta.from_date"
              placeholder="dd/mm/yyyy"
            ></datepicker>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">To (Optional)</label>
            <datepicker
              input-class="uk-input"
              name="end_date"
              v-model="meta.to_date"
              placeholder="dd/mm/yyyy"
            ></datepicker>
          </div>

        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-primary" type="button" @click="setDate">Save</button>
        </div>
      </div>
    </div>

    <div id="add-schedule" uk-modal esc-close="false" bg-close="true">
        <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" type="button" uk-close @click="hideCreateModal"></button>
            <div class="uk-modal-header">
                <h2 class="uk-modal-title">Create</h2>
            </div>
            <div class="uk-modal-body">
                <div class="uk-margin">
                    <label class="uk-form-label" for="form-stacked-text">Shift</label>
                    <select
                        style="margin-top:10px;width:326px;display:block"
                        name="Updated By Filter"
                        class="uk-select"
                        id="form-stacked-select"
                        v-model="createSchedule.companyShift"
                    >
                        <option value="" disabled selected>Pilih Shift</option>
                        <option v-for="data in companyShift" :value="data._id" :key="data._id">{{data.name}}</option>
                    </select>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label" for="form-stacked-text">Hari</label>
                    <div class="uk-grid-small uk-child-width-1-4@s" uk-grid style="margin-top:10px">
                            <div>
                            <button @click="handleClickDay('senin')"
                            :class="(createSchedule.btnSenin)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Senin</button>
                            </div>
                            <div>
                            <button @click="handleClickDay('selasa')"
                            :class="(createSchedule.btnSelasa)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Selasa</button>
                            </div>
                            <div>
                            <button @click="handleClickDay('rabu')"
                            :class="(createSchedule.btnRabu)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Rabu</button>
                            </div>
                            <div>
                            <button @click="handleClickDay('kamis')"
                            :class="(createSchedule.btnKamis)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Kamis</button>
                            </div>
                    </div>
                    <div class="uk-grid-small uk-child-width-1-4@s" uk-grid style="margin-top:10px">
                            <div>
                            <button @click="handleClickDay('jumat')"
                            :class="(createSchedule.btnJumat)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Jumat</button>
                            </div>
                            <div>
                            <button @click="handleClickDay('sabtu')"
                            :class="(createSchedule.btnSabtu)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Sabtu</button>
                            </div>
                            <div>
                            <button @click="handleClickDay('minggu')"
                            :class="(createSchedule.btnMinggu)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Minggu</button>
                            </div>
                    </div>
                </div>
                <p>Masa Berlaku</p>
                <div class="uk-width-1-1 uk-column-1-2@m">
                        <label class="uk-form-label" for="form-stacked-text">Dari</label>
                        <div class="uk-form-controls">
                        <datetime
                            input-class="uk-input"
                            type="date"
                            name="Start Date"
                            :min-datetime="moment().format()"
                            v-model="createSchedule.start_date"
                            placeholder="dd/mm/yyy"
                        ></datetime>
                        <label class="uk-form-label" for="form-stacked-text">Sampai (Optional)</label>
                        <div class="uk-form-controls">
                            <datetime
                                input-class="uk-input"
                                type="date"
                                name="End Date"
                                :min-datetime="createSchedule.start_date"
                                v-model="createSchedule.end_date"
                                placeholder="dd/mm/yyy"
                            ></datetime>
                        </div>
                    </div>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label" for="form-stacked-text">Role</label>
                    <select
                        style="margin-top:10px;width:326px;display:block"
                        name="Updated By Filter"
                        class="uk-select"
                        id="form-stacked-select"
                        v-model="createSchedule.role"
                    >
                        <option value="" disabled selected>Pilih Role</option>
                        <option v-for="data in roleList.docs" :value="data._id" :key="data._id">{{data.name}}</option>
                    </select>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label">Workers</label>
                    <multiselect
                    :disabled="!allowSelectWorker()"
                    v-model="createSchedule.currentUser"
                    placeholder="Pilih Pekerja"
                    label="fullname"
                    track-by="fullname"
                    :multiple="true"
                    :close-on-select="true"
                    :options="userList"
                    @select="confirmWorkerOnCreate">
                    </multiselect>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="handleCreate">Simpan</button>
                </div>
            </div>
        </div>
    </div>

        <div id="availability-warning" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <div class="uk-modal-header">
                    <h3 class="uk-flex uk-flex-center uk-flex-middle">
                        {{this.availability.user.fullname}} sudah berada pada shift lain di waktu yang sama
                    </h3>
                </div>
                <div class="uk-modal-body uk-flex uk-flex-center uk-flex-middle uk-margin-remove-bottom uk-padding">
                    <p>
                        {{this.availability.user.fullname}} sudah berada pada {{this.availability.shift.name}} yang berjalan pada pukul
                        {{this.availability.shift.start_shift.hour}}.{{("0" + this.availability.shift.start_shift.minute).slice(-2)}} -
                        {{this.availability.shift.end_shift.hour}}.{{("0" + this.availability.shift.end_shift.minute).slice(-2)}}. Silahkan pilih mitra lain!
                    </p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button
                      class="uk-button uk-button-primary uk-margin-small-left" type="button"
                      @click="onMitraUnavailable(availability.isCreate)">OK</button>
                </div>
            </div>
        </div>

        <div id="confirmation-select-workers" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('confirmation-select-workers')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-left" style="font-size:18px">
                        {{this.availability.user.fullname}} sudah berada di shift {{this.availability.shift.name}}!</h2>
                </div>
                <div class="uk-modal-body">
                    <p>Apakah Anda ingin memasukkan {{this.availability.user.fullname}} pada shift ini?</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger uk-margin-small-left" type="button"  @click="onMitraUnavailable2(availability.isCreate)">Batalkan</button>
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button"
                      @click="showCreateModal">Ya</button>
                </div>
            </div>
        </div>

    <div id="delete-schedule-modal" uk-modal>
        <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('delete-schedule-modal')"></button>
            <div class="uk-modal-header">
                <h2 class="uk-modal-title">Delete</h2>
            </div>
            <div class="uk-modal-body">
                <multiselect
                    v-model="dateSelected"
                    placeholder="Pick date"
                    label="dateString"
                    track-by="date"
                    :multiple="true"
                    :close-on-select="true"
                    :options="dayOptions">
                </multiselect>
            </div>
            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" type="button" @click="hideModal('delete-schedule-modal')">Cancel</button>
                <button class="uk-button uk-button-danger uk-margin-small-left" type="button" @click="handleDeleteConfirm(true)">Delete all</button>
                <button class="uk-button uk-button-danger uk-margin-small-left" type="button" @click="handleDeleteConfirm(false)" :disabled="dayOptions.length == 0">Delete selected days</button>
            </div>
        </div>
    </div>

        <div id="delete-success" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('delete-success')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Delete Success</h2>
                </div>
                <div class="uk-modal-body">
                    <p v-if="isDeleteAll">Schedule with ID {{selectedSchedule._id}} has been deleted!</p>
                    <p v-else>Schedule in selected days has been deleted!</p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="hideModal('delete-success')">Back</button>
                </div>
            </div>
        </div>

        <div id="notif-mitra" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('notif-mitra')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Notify</h2>
                </div>
                <div class="uk-modal-body uk-flex uk-flex-center uk-flex-middle uk-margin-remove-bottom uk-padding">
                    <h2 class="uk-modal-title" style="font-size:18px">
                        Are you sure to send notification to mitra?</h2>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger uk-margin-small-left" type="button" @click="hideModal('notif-mitra')">No</button>
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="publishSchedule">Yes</button>

                </div>
            </div>
        </div>

        <div id="notif-success" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('notif-success')"></button>
                <div class="uk-modal-body">
                    <h2 class="uk-modal-title uk-text-left" style="font-size:18px;text-align:center !important">
                        Notification Sent!</h2>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="hideModal('notif-success')">Back</button>
                </div>
            </div>
        </div>

        <div id="create-success" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('create-success')"></button>
                <div class="uk-modal-body">
                    <h2 class="uk-modal-title uk-text-left" style="font-size:18px;text-align:center !important">
                        You are success to create new Schedule!</h2>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="hideModal('create-success')">Back</button>
                </div>
            </div>
        </div>

        <div id="edit-success" uk-modal esc-close="false" bg-close="true">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('edit-success')"></button>
                <div class="uk-modal-body">
                    <h2 class="uk-modal-title uk-text-left" style="font-size:18px;text-align:center !important">
                        You are success to edit the Schedule!</h2>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="hideModal('edit-success')">Back</button>
                </div>
            </div>
        </div>

    <div id="edit-schedule" uk-modal esc-close="false" bg-close="true">
        <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('edit-schedule')"></button>
            <div class="uk-modal-header">
                <h2 class="uk-modal-title">Edit</h2>
            </div>
            <div class="uk-modal-body">
                <div class="uk-margin">
                    <label class="uk-form-label" for="form-stacked-text">Shift</label>
                    <select
                        style="margin-top:10px;width:326px;display:block"
                        name="Updated By Filter"
                        class="uk-select"
                        id="form-stacked-select"
                        v-model="detailSchedule.companyShift"
                    >
                        <option value="" disabled selected>Pilih Shift</option>
                        <option v-for="data in companyShift" :value="data._id" :key="data._id">{{data.name}}</option>
                    </select>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label" for="form-stacked-text">Hari</label>
                    <div class="uk-grid-small uk-child-width-1-4@s" uk-grid style="margin-top:10px">
                            <div>
                            <button @click="handleClickDayEdit('senin')"
                            :class="(detailSchedule.btnSenin)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Senin</button>
                            </div>
                            <div>
                            <button @click="handleClickDayEdit('selasa')"
                            :class="(detailSchedule.btnSelasa)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Selasa</button>
                            </div>
                            <div>
                            <button @click="handleClickDayEdit('rabu')"
                            :class="(detailSchedule.btnRabu)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Rabu</button>
                            </div>
                            <div>
                            <button @click="handleClickDayEdit('kamis')"
                            :class="(detailSchedule.btnKamis)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Kamis</button>
                            </div>
                    </div>
                    <div class="uk-grid-small uk-child-width-1-4@s" uk-grid style="margin-top:10px">
                            <div>
                            <button @click="handleClickDayEdit('jumat')"
                            :class="(detailSchedule.btnJumat)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Jumat</button>
                            </div>
                            <div>
                            <button @click="handleClickDayEdit('sabtu')"
                            :class="(detailSchedule.btnSabtu)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Sabtu</button>
                            </div>
                            <div>
                            <button @click="handleClickDayEdit('minggu')"
                            :class="(detailSchedule.btnMinggu)?'btn-sml-active uk-button uk-button-primary uk-button-small':'btn-sml uk-button uk-button-primary uk-button-small'">Minggu</button>
                            </div>
                    </div>
                </div>
                <p>Masa Berlaku</p>
                <div class="uk-width-1-1 uk-column-1-2@m">
                        <label class="uk-form-label" for="form-stacked-text">Dari</label>
                        <div class="uk-form-controls">
                        <datetime
                            input-class="uk-input"
                            type="date"
                            name="Start Date"
                            :min-datetime="moment().format()"
                            v-model="detailSchedule.start_date"
                            placeholder="dd/mm/yyy"
                        ></datetime>
                        <label class="uk-form-label" for="form-stacked-text">Sampai (Optional)</label>
                        <div class="uk-form-controls">
                            <datetime
                                input-class="uk-input"
                                type="date"
                                name="End Date"
                                :min-datetime="detailSchedule.start_date"
                                v-model="detailSchedule.end_date"
                                placeholder="dd/mm/yyy"
                            ></datetime>
                        </div>
                    </div>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label" for="form-stacked-text">Role</label>
                    <select
                        style="margin-top:10px;width:326px;display:block"
                        name="Updated By Filter"
                        class="uk-select"
                        id="form-stacked-select"
                        v-model="detailSchedule.role"
                    >
                        <option value="" disabled selected>Pilih Role</option>
                        <option v-for="data in roleList.docs" :value="data._id" :key="data._id">{{data.name}}</option>
                    </select>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label">Workers</label>
                    <multiselect
                    v-model="detailSchedule.currentUser"
                    placeholder="Pilih Pekerja"
                    label="fullname"
                    track-by="fullname"
                    :multiple="true"
                    :close-on-select="true"
                    :options="userList"
                    @select="checkAvailability">
                    </multiselect>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="handleEdit">Simpan</button>
                </div>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import {
    notificationDangerCustom
} from '@/utils/notification';
import {Datetime} from 'vue-datetime';
import Datepicker from 'vuejs-datepicker';

export default {
    data() {
        return {
            moment: moment,
            meta: {
                limit: 100,
                page: 1,
                from_date: '',
                to_date: '',
                company_office_id: '',
                is_deleted: false,
            },
            selectedSchedule: '',
            createSchedule: {
                btnSenin: false,
                btnSelasa: false,
                btnRabu: false,
                btnKamis: false,
                btnJumat: false,
                btnSabtu: false,
                btnMinggu: false,
                start_date: null,
                end_date: null,
                specialScheduleText: null,
                companyShift: '',
                role: null,
                isSpecialSchedule: false,
                currentUser: []
            },
            detailSchedule: {
                btnSenin: false,
                btnSelasa: false,
                btnRabu: false,
                btnKamis: false,
                btnJumat: false,
                btnSabtu: false,
                btnMinggu: false,
                start_date: null,
                specific: {date:null,day:null},
                end_date: null,
                companyShift: '',
                role: null,
                isSpecialSchedule: false,
                specialScheduleText: null,
                currentUser: []
            },
            filterBtnText: '',
            company_office: {
                name: '',
                code: ''
            },
            isLoading: false,
            userList: [],
            companyShift: [],
            dateInWeek: {},
            dayOptions: [],
            dateSelected: [],
            isDeleteAll: false,
            availability: {
                user: {},
                shift: {
                    start_shift: 0,
                    end_shift: 0,
                },
                isCreate: false,
            },
        };
    },
    components: {
        Pagination,
        EmptyTable,
        Datetime,
        Datepicker
    },
    watch: {
        meta() {
            this.scheduleMeta();
        },
    },
    async mounted() {
        this.scheduleMeta();
        await this.setCompanyOffice();
        this.filterDateText();
        await this.getCompanyRoles({company_id: this.company_office.company._id});
        const cmp = await this.getAllCompanyShift({companyOfficeId: this.company_office._id});
        this.companyShift = cmp;
        const users = await this.getUserPartner({company_office_id: this.company_office._id, company_id: this.company_office.company._id});
        this.userList = users;
    },
    computed: {
        ...mapGetters({
            schedules: 'schedule/schedules',
            schedulesCalendar: 'schedule/schedulesCalendar',
            property: 'property/property',
            roleList: 'company_role/company_roles'
        }),
    },
    methods: {
        ...mapActions({
            getSchedules: 'schedule/getSchedules',
            getSchedulesinCalendar: 'schedule/getSchedulesinCalendar',
            getPropertyDetail: 'property/getPropertyDetail',
            getCompanyRoles: 'company_role/getCompanyRoles',
            getUserPartner: 'schedule/getUserPartner',
            getAllCompanyShift: 'schedule/getAllCompanyShift',
            getShiftDetail: 'option_data/getShiftDetail',
            checkAvailable: 'schedule/checkAvailable',
            postCreateSchedule: 'schedule/postCreateSchedule',
            deleteScheduleShift: 'schedule/deleteScheduleShift',
            postNotifSchedule: 'schedule/postNotifSchedule',
            showScheduleShift: 'schedule/showScheduleShift',
            putEditSchedule: 'schedule/putEditSchedule',
            deleteSpecificScheduleShift: 'schedule/deleteSpecificScheduleShift',
        }),
        days(schedule) {
            let validDays = '';
            if (schedule.monday) {
                validDays += 'Monday, ';
            }
            if (schedule.tuesday) {
                validDays += 'Tuesday, ';
            }
            if (schedule.wednesday) {
                validDays += 'Wednesday, ';
            }
            if (schedule.thursday) {
                validDays += 'Thursday, ';
            }
            if (schedule.friday) {
                validDays += 'Friday';
            }
            if (schedule.saturday) {
                validDays += ' ,Saturday, ';
            }
            if (schedule.sunday) {
                validDays += 'Sunday';
            }
            if (validDays == '') {
                validDays = '-';
            }
            return validDays;
        },
        allowSelectWorker() {
            const daySelected =
                this.createSchedule.btnSenin || this.createSchedule.btnSelasa ||
                this.createSchedule.btnRabu || this.createSchedule.btnKamis ||
                this.createSchedule.btnJumat || this.createSchedule.btnSabtu ||
                this.createSchedule.btnMinggu;

            return this.createSchedule.start_date && this.createSchedule.companyShift &&  daySelected;
        },
        handleClickDay (day) {
            if (day == 'senin') {
                this.createSchedule.btnSenin = !this.createSchedule.btnSenin;
            }
            if (day == 'selasa') {
                this.createSchedule.btnSelasa = !this.createSchedule.btnSelasa;
            }
            if (day == 'rabu') {
                this.createSchedule.btnRabu = !this.createSchedule.btnRabu;
            }
            if (day == 'kamis') {
                this.createSchedule.btnKamis = !this.createSchedule.btnKamis;
            }
            if (day == 'jumat') {
                this.createSchedule.btnJumat = !this.createSchedule.btnJumat;
            }
            if (day == 'sabtu') {
                this.createSchedule.btnSabtu = !this.createSchedule.btnSabtu;
            }
            if (day == 'minggu') {
                this.createSchedule.btnMinggu = !this.createSchedule.btnMinggu;
            }
        },
        handleClickDayEdit (day) {
            if (day == 'senin') {
                this.detailSchedule.btnSenin = !this.detailSchedule.btnSenin;
            }
            if (day == 'selasa') {
                this.detailSchedule.btnSelasa = !this.detailSchedule.btnSelasa;
            }
            if (day == 'rabu') {
                this.detailSchedule.btnRabu = !this.detailSchedule.btnRabu;
            }
            if (day == 'kamis') {
                this.detailSchedule.btnKamis = !this.detailSchedule.btnKamis;
            }
            if (day == 'jumat') {
                this.detailSchedule.btnJumat = !this.detailSchedule.btnJumat;
            }
            if (day == 'sabtu') {
                this.detailSchedule.btnSabtu = !this.detailSchedule.btnSabtu;
            }
            if (day == 'minggu') {
                this.detailSchedule.btnMinggu = !this.detailSchedule.btnMinggu;
            }
        },
        async showCreateModal() {
            await window.UIkit.modal('#add-schedule').show();
        },
        async hideCreateModal() {
            await window.UIkit.modal('#add-schedule').hide();
            this.createSchedule = {
                btnSenin: false,
                btnSelasa: false,
                btnRabu: false,
                btnKamis: false,
                btnJumat: false,
                btnSabtu: false,
                btnMinggu: false,
                start_date: null,
                end_date: null,
                specialScheduleText: null,
                companyShift: '',
                role: null,
                isSpecialSchedule: false,
                currentUser: []
            };
        },
        async confirmWorkerOnCreate(user) {
            const {companyShift, start_date} = this.createSchedule;
            const shiftDetail = await this.getShiftDetail(companyShift);

            const selectedDays = [
                'btnSenin', 'btnSelasa', 'btnRabu', 'btnKamis',
                'btnJumat', 'btnSabtu', 'btnMinggu'
            ]
                .map(day => this.createSchedule[day])
                .reduce((next, bool, index) => bool ? next.concat(index + 1) : next, []);

            const startDay = moment(start_date).isoWeekday();
            const optSchedule = {shift: shiftDetail};

            // Immediately rejects all upon one rejection
            await Promise.all(selectedDays.map(day => startDay <= day
                ? this.checkAvailability(user, true, {...optSchedule, date: moment(start_date).isoWeekday(day).toISOString()})
                : this.checkAvailability(user, true, {...optSchedule, date: moment(start_date).add(1, 'weeks').isoWeekday(day).toISOString()})
            ));
        },
        async checkAvailability(user, isCreate = false, optSchedule = {}) {
            if (isCreate) {
                const response = await this.checkAvailable({
                    date: isCreate ?  optSchedule.date : this.detailSchedule.date,
                    user_id: user._id,
                    company_id: this.company_office.company._id
                });
                if (!response) return Promise.resolve();

                let currentStart =  isCreate
                    ? optSchedule.shift.start_shift.hour
                    : this.detailSchedule.companyShiftDetail.start_shift.hour;
                let currentEnd = isCreate
                    ? optSchedule.shift.end_shift.hour
                    : this.detailSchedule.companyShiftDetail.end_shift.hour;

                let latterStart = response.start_shift.hour;
                let latterEnd = response.end_shift.hour;

                if (currentEnd < currentStart) currentEnd += 24;
                if (latterEnd < latterStart) latterEnd += 24;

                if ( // Overlap
                    latterStart <= currentEnd
                    &&
                    latterEnd >= currentStart
                ) {
                    this.availability.shift = response;
                    this.availability.user = user;
                    this.availability.isCreate = isCreate;
                    this.showModal('availability-warning');
                    return Promise.reject();
                } else if (response !== null) {
                    this.availability.shift = response;
                    this.availability.user = user;
                    this.availability.isCreate = isCreate;
                    this.showModal('confirmation-select-workers');
                    return Promise.reject();
                }
                return Promise.resolve();
            } else {
                for (let index = 1; index <= 7; index++) {
                    const response = await this.checkAvailable({
                        date: isCreate ?  optSchedule.date : moment().add(index, 'days').isoWeekday(index).toISOString(),
                        user_id: user._id,
                        company_id: this.company_office.company._id
                    });
                    if (!response) return Promise.resolve();

                    let currentStart =  isCreate
                        ? optSchedule.shift.start_shift.hour
                        : this.detailSchedule.companyShiftDetail.start_shift.hour;
                    let currentEnd = isCreate
                        ? optSchedule.shift.end_shift.hour
                        : this.detailSchedule.companyShiftDetail.end_shift.hour;

                    let latterStart = response.start_shift.hour;
                    let latterEnd = response.end_shift.hour;

                    if (currentEnd < currentStart) currentEnd += 24;
                    if (latterEnd < latterStart) latterEnd += 24;

                    if ( // Overlap
                        latterStart <= currentEnd
                        &&
                        latterEnd >= currentStart
                    ) {
                        this.availability.shift = response;
                        this.availability.user = user;
                        this.availability.isCreate = isCreate;
                        this.showModal('availability-warning');
                        return Promise.reject();
                    } else if (response !== null) {
                        this.availability.shift = response;
                        this.availability.user = user;
                        this.availability.isCreate = isCreate;
                        this.showModal('confirmation-select-workers');
                        return Promise.reject();
                    }
                }
                return Promise.resolve();
            }
        },
        async hideModal(modal_name) {
            switch(modal_name) {
            case 'confirmation-select-workers':
                await window.UIkit.modal('#confirmation-select-workers').hide();
                break;
            case 'notif-mitra':
                this.selectedSchedule = '';
                await window.UIkit.modal('#notif-mitra').hide();
                break;
            case 'notif-success':
                await window.UIkit.modal('#notif-success').hide();
                this.selectedSchedule = '';
                this.scheduleMeta();
                break;
            case 'delete-success':
                await window.UIkit.modal('#delete-success').hide();
                this.selectedSchedule = '';
                this.dayOptions = [];
                this.dateSelected = [];
                this.scheduleMeta();
                break;
            case 'availability-warning':
                await window.UIkit.modal('#availability-warning').hide();
                break;
            case 'edit-schedule':
                await window.UIkit.modal('#edit-schedule').hide();
                this.detailSchedule = '';
                break;
            case 'create-success':
                await window.UIkit.modal('#create-success').hide();
                break;
            case 'edit-success':
                await window.UIkit.modal('#edit-success').hide();
                break;
            case 'delete-schedule-modal':
                this.dayOptions = [];
                this.dateSelected = [];
                await window.UIkit.modal('#delete-schedule-modal').hide();
                break;
            }

        },
        async showModal(modal_name) {
            switch(modal_name) {
            case 'delete-success':
                await window.UIkit.modal('#delete-success').show();
                break;
            case 'notif-mitra':
                await window.UIkit.modal('#notif-mitra').show();
                break;
            case 'notif-success':
                await window.UIkit.modal('#notif-success').show();
                break;
            case 'confirmation-select-workers':
                await window.UIkit.modal('#confirmation-select-workers').show();
                break;
            case 'availability-warning':
                await window.UIkit.modal('#availability-warning').show();
                break;
            case 'create-success':
                await window.UIkit.modal('#create-success').show();
                break;
            case 'edit-success':
                await window.UIkit.modal('#edit-success').show();
                break;
            }
        },
        async onMitraUnavailable(isCreate) {
            this[isCreate ? 'createSchedule' : 'detailSchedule'].currentUser =
            this[isCreate ? 'createSchedule' : 'detailSchedule'].currentUser.filter(
                user => user._id !== this.availability.user._id
            );

            this.availability = {
                user: {},
                shift: {
                    start_shift: 0,
                    end_shift: 0,
                },
                isCreate: false,
            };
            this.hideModal('availability-warning');
            isCreate ? this.showCreateModal() : await window.UIkit.modal('#edit-schedule').show();
        },
        onMitraUnavailable2(isCreate) {
            this[isCreate ? 'createSchedule' : 'detailSchedule'].currentUser =
            this[isCreate ? 'createSchedule' : 'detailSchedule'].currentUser.filter(
                user => user._id !== this.availability.user._id
            );

            this.availability = {
                user: {},
                shift: {
                    start_shift: 0,
                    end_shift: 0,
                },
                isCreate: false,
            };
            this.hideModal('confirmation-select-workers');
            this.showCreateModal();
        },
        resetForm(){
            this.createSchedule = {
                btnSenin: false,
                btnSelasa: false,
                btnRabu: false,
                btnKamis: false,
                btnJumat: false,
                btnSabtu: false,
                btnMinggu: false,
                start_date: null,
                end_date: null,
                specialScheduleText: null,
                companyShift: '',
                role: null,
                isSpecialSchedule: false,
                currentUser: []
            };
            this.detailSchedule = {
                btnSenin: false,
                specific: {date:null,day:null},
                btnSelasa: false,
                btnRabu: false,
                btnKamis: false,
                btnJumat: false,
                btnSabtu: false,
                btnMinggu: false,
                start_date: null,
                end_date: null,
                companyShift: '',
                role: null,
                isSpecialSchedule: false,
                specialScheduleText: null,
                currentUser: []
            };
        },
        async handleCreate() {
            const userArr = [];
            let valid = true;
            this.createSchedule.currentUser.map((it) => {
                userArr.push(it._id);
            });
            const payload = {
                "company_id": this.company_office.company._id,
                "company_shift_id": this.createSchedule.companyShift,
                "company_role_id": this.createSchedule.role,
                "is_special_schedule": this.createSchedule.isSpecialSchedule,
                "name": (this.specialScheduleValue != '') ? this.specialScheduleValue : null,
                "is_valid_forever": false,
                "valid_from": this.createSchedule.start_date,
                "valid_to": (this.createSchedule.end_date != '')?this.createSchedule.end_date:null,
                "monday": this.createSchedule.btnSenin,
                "tuesday": this.createSchedule.btnSelasa,
                "wednesday": this.createSchedule.btnRabu,
                "thursday": this.createSchedule.btnKamis,
                "friday": this.createSchedule.btnJumat,
                "saturday": this.createSchedule.btnSabtu,
                "sunday": this.createSchedule.btnMinggu,
                "users": userArr
            };
            if (this.createSchedule.currentUser == 0) {
                valid = false;
                return notificationDangerCustom('Pilih pekerja terlebih dahulu');
            }
            if (this.createSchedule.start_date == '' || this.createSchedule.start_date == null) {
                valid = false;
                return notificationDangerCustom('Silakan isi tanggal mulai terlebih dahulu');
            }
            const res = await this.postCreateSchedule(payload);
            if (res.toLowerCase() == 'success' && valid) {
                this.hideCreateModal();
                this.showModal('create-success');
                this.resetForm();
                this.scheduleMeta();
            }
        },
        async handleDeleteConfirm(deleteAll){
            let res = '';
            this.isDeleteAll = deleteAll;
            if (deleteAll) {
                res = await this.deleteScheduleShift({id:this.selectedSchedule._id, company_id: this.company_office.company._id});
                if (process.env.NODE_ENV !== 'production') {
                    console.log('resDelete', res);
                }
                if (res.toLowerCase() == 'success') {
                    this.showModal('delete-success');
                }
            } else {
                if (this.dateSelected.length == 0) {
                    return notificationDangerCustom('Silakan pilih tanggal terlebih dahulu!');
                } else {
                    for (const element of this.dateSelected) {
                        res = await this.deleteSpecificScheduleShift({id:this.selectedSchedule._id, date:element.date});
                        if (process.env.NODE_ENV !== 'production') {
                            console.log('resDelete', res);
                        }
                        if (res.toLowerCase() != 'success') {
                            return notificationDangerCustom('Error delete schedule on ', element.dateString);
                        }
                    }
                    if (res.toLowerCase() == 'success') {
                        this.showModal('delete-success');
                    }
                }
            }
        },
        async showEditModal(schedule){
            this.detailSchedule = schedule;
            const res = await this.showScheduleShift({id:schedule._id,company_id: this.company_office.company._id});
            let users = [];
            res.users.map((item) => {
                users.push({
                    _id: item._id,
                    fullname: item.name,
                    status_worker: item.status_worker
                });
            });
            this.detailSchedule = {
                _id: schedule._id,
                btnSenin: res.monday,
                btnSelasa: res.tuesday,
                btnRabu: res.wednesday,
                btnKamis: res.thursday,
                btnJumat: res.friday,
                btnSabtu: res.saturday,
                btnMinggu: res.sunday,
                is_valid_forever: res.is_valid_forever,
                name: res.company_shift.name,
                createdAt: res.created_at,
                start_date: res.valid_from,
                end_date: res.valid_to,
                companyShift: res.company_shift._id,
                companyShiftDetail: res.company_shift,
                role: (res.company_role) ? res.company_role._id : null,
                isSpecialSchedule: false,
                currentUser: users
            };
            await window.UIkit.modal('#edit-schedule').show();

        },
        async handleEdit(){
            const userArr = [];
            this.detailSchedule.currentUser.map((it) => {
                userArr.push(it._id);
            });
            const payload = {
                "company_id": this.company_office.company._id,
                "company_shift_id": this.detailSchedule.companyShift,
                "company_role_id": this.detailSchedule.role,
                "is_special_schedule": this.detailSchedule.isSpecialSchedule,
                "name": (this.specialScheduleValue != '') ? this.specialScheduleValue : null,
                "is_valid_forever": false,
                "valid_from": this.detailSchedule.start_date,
                "valid_to": (this.detailSchedule.end_date != '')?this.detailSchedule.end_date:null,
                "monday": this.detailSchedule.btnSenin,
                "tuesday": this.detailSchedule.btnSelasa,
                "wednesday": this.detailSchedule.btnRabu,
                "thursday": this.detailSchedule.btnKamis,
                "friday": this.detailSchedule.btnJumat,
                "saturday": this.detailSchedule.btnSabtu,
                "sunday": this.detailSchedule.btnMinggu,
                "users": userArr
            };
            if (this.detailSchedule.currentUser == 0) {
                return notificationDangerCustom('Pilih pekerja terlebih dahulu');
            }
            const res = await this.putEditSchedule({payload:payload,id:this.detailSchedule._id});
            if (res.toLowerCase() == 'success') {
                this.hideModal('edit-schedule');
                this.showModal('edit-success');
                this.resetForm();
                this.scheduleMeta();
            }
        },
        async showDeleteModal(schedule) {
            this.selectedSchedule = schedule;
            await this.getCalendarSchedules();
            this.getCurrentWeek();
            this.getAllDayOptions(schedule);
            await window.UIkit.modal('#delete-schedule-modal').show();
        },
        async showNotifyModal(schedule) {
            this.selectedSchedule = schedule;
            await window.UIkit.modal('#notif-mitra').show();
        },
        async publishSchedule() {
            // TODO: Integrate to API publish schedule
            if (process.env.NODE_ENV !== 'production') {
                console.log('Publish', this.selectedSchedule);
            }
            const res = await this.postNotifSchedule({id:this.selectedSchedule._id, date:moment().toISOString(), company_id: this.company_office.company._id});
            if (res.toLowerCase() == 'success') {
                this.showModal('notif-success');
            }
        },
        async scheduleMeta() {
            this.meta.company_office_id = this.$route.params.company_office_id.toString();
            this.meta.company_id = this.$route.params.company_id.toString();
            this.meta.company_role_id = '';
            await this.getSchedules(this.meta);
        },
        async getCalendarSchedules() {
            let sentMeta = {
                company_office_id: this.$route.params.company_office_id.toString(),
                company_id: this.$route.params.company_id.toString(),
                company_role_id: '',
                from_date: moment().utc().startOf('day').toISOString(),
                to_date: moment().utc().startOf('day').add(6, 'days').toISOString(),
                view_in: 'calendar'
            };
            await this.getSchedulesinCalendar(sentMeta);
        },
        async setCompanyOffice() {
            this.id = this.$route.params.company_office_id.toString();
            await this.getPropertyDetail(this.id);
            this.company_office = this.property;
        },
        async setDate() {
            this.scheduleMeta();
            this.filterDateText();
            await window.UIkit.modal('#filterDateModal').hide();
        },
        dateFilterAsText() {
            let date_format = '';
            if (this.meta.from_date) {
                date_format = date_format + this.formatDate(this.meta.from_date);
            }
            if (this.meta.to_date) {
                if (date_format !== '') {
                    date_format = date_format + '-';
                }
                date_format = date_format + this.formatDate(this.meta.to_date);
            }
            return date_format;
        },
        filterDateText() {
            let date_filter_text = this.dateFilterAsText();
            if (date_filter_text != '') {
                return this.filterBtnText = date_filter_text;
            } else {
                return this.filterBtnText = '';
            }
        },
        clearFilterDate() {
            this.resetDate();
            this.scheduleMeta();
        },
        formatDay(date) {
            if (!date) return null;
            return moment(date).format('dddd');
        },
        formatDate(date) {
            if (!date) return null;
            return moment(date).format('DD MMM YYYY');
        },
        backSchedule() {
            this.$router.push(`/admin/mitra/schedule/`);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async showFilterModal() {
            await window.UIkit.modal('#filterDateModal').show();
        },
        async hideFilterModal() {
            await window.UIkit.modal('#filterDateModal').hide();
            this.resetDate();
        },
        resetDate() {
            this.meta = {
                from_date: '',
                to_date: '',
            };
        },
        formatTime(time) {
            time = String(time);
            if (time.length == 1) {
                return '0'.concat(time);
            } else {
                return time;
            }
        },
        getCurrentWeek() {
            let dateWeek = [];
            for (let index = 0; index <= 6; index++) {
                let theDay = {
                    id: moment().utc().startOf('day').add(index, 'days').isoWeekday(),
                    date: moment().utc().startOf('day').add(index, 'days').toISOString(),
                    dateString: moment().utc().startOf('day').add(index, 'days').format('dddd, DD-MMM-YYYY').toString()
                };
                dateWeek.push(theDay);
            }
            this.dateInWeek = dateWeek;
        },
        getDays(isoWeekday){
            this.dateInWeek.forEach(element => {
                if (element.id == isoWeekday) {
                    this.dayOptions.push(element);
                }
            });
        },
        getAllDayOptions(schedule) {
            this.schedulesCalendar.docs.forEach(el => {
                if (el.schedule_shifts.length != 0 ) {
                    el.schedule_shifts.forEach(element => {
                        if (element._id == schedule._id) {
                            this.getDays(moment(el.date).isoWeekday());
                        }
                    });
                }
            });
        },
    }
};
</script>
<style scoped>
.btn-sml-active{
    background: #0ABAB5;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    width: 101px;
    text-align: center;
    padding-left:0;
    padding-right: 0;
    color: #ffffff !important;
}
.btn-sml{
    background: #F8F8F8;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    padding-left:0;
    padding-right: 0;
    width: 101px;
    color: #000000 !important;
}
</style>
